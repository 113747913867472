<div
  elTestId="jPzTU9SNdmxbmoqT56K5N"
  class="fieldset col-md-12 mt-4"
>
  <div
    elTestId="0oRkZgfzgon05Qed1Hzu_"
    class="header d-flex align-items-center"
  >
    <div
      elTestId="jqdSh0CpY7Hnsa4B8PVXi"
      class="a-text"
    >
      <h5
        elTestId="r_1hv37VBxGvUdGEb_fTf"
        [ngClass]="{
          'section-disabled': activeTillDisplayOrder < to['displayOrder']
        }"
      >
        {{ to['heading'] }}
        <button
          *ngIf="
            to['infoTextKey'] && activeTillDisplayOrder >= to['displayOrder']
          "
          elTestId="38J61cIeNfRkwsXriD17o"
          type="button"
          style="vertical-align: middle"
          class="a-button a-button--integrated -without-label"
          (click)="onInfoTextIconClick()"
        >
          <em
            elTestId="yQjUkZgoADVn0y3gOYs1I"
            class="a-icon boschicon-bosch-ic-alert-info"
            title="{{ 'ddaap.common.infoTextButton' | translate }}"
          ></em>
        </button>
      </h5>
    </div>
  </div>
  <div
    *ngIf="activeTillDisplayOrder >= to['displayOrder']"
    elTestId="-KBIE8Es5eEG_kQGRDB5I"
    class="body col-md-12"
  >
    <div elTestId="xlI5pgDAqTWQOxGnJOHo0">
      <div
        elTestId="oHZKChdc-LLJveF5Jmd5D"
        class="description a-text"
      >
        <p elTestId="qq_D1XycW5kBE67apacFG">
          {{ to['description'] }}
        </p>
      </div>
      <div>
        <ng-container>
          <mat-grid-list
            [cols]="radiosPerRow"
            [rowHeight]="'3rem'"
          >
            <mat-grid-tile
              *ngFor="let option of to['options'] | keyvalue; let i = index"
            >
              <div
                elTestId="U6onUmaEP5Mwja2BjWdNR"
                class="m-form-field m-form-field--radio driver-input-field"
              >
                <div
                  elTestId="JX9dfOt8VXZEaF39hNn79"
                  class="a-radio-button"
                >
                  <input
                    elTestId="xEF9xYsJPeLbx2xocn6kR"
                    type="radio"
                    [id]="fieldKey + option.value.value"
                    [value]="option.value.value"
                    [formControl]="control"
                    [name]="id"
                  />

                  <label
                    elTestId="FdLDW3T24C2KtLk9aNjD7"
                    [for]="fieldKey + option.value.value"
                    >{{ option.value.label }}</label
                  >
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </ng-container>
      </div>
      <div
        *ngIf="to['extensionText']"
        class="a-notification a-notification--text extension-text"
        role="alert"
      >
        <em
          class="a-icon ui-ic-alert-info"
          title="{{ 'ddaap.common.infoTextButton' | translate }}"
        ></em>
        <div
          id="notification-label-id-text-neutral"
          class="a-notification__content"
        >
          {{ to['extensionText'] }}
        </div>
      </div>
    </div>
  </div>
</div>
