<div
  elTestId="NUAHfH8xyBU92cD80aRak"
  class="fieldset mt-4 col-md-12"
>
  <div
    elTestId="8oQFcmdnOu6eBq3O8wb4P"
    class="header d-flex align-items-center"
  >
    <div
      elTestId="mNctYFqq46xn0gxwUHTYe"
      class="a-text"
    >
      <span elTestId="E71KX3pb-uVABsMn7p-PW">
        <h5
          elTestId="fqBcVJgpr2VT9wymwjF9q"
          [ngClass]="{
            'section-disabled': activeTillDisplayOrder < to['displayOrder']
          }"
        >
          {{ to['heading'] }}
          <button
            *ngIf="
              to['infoTextKey'] && activeTillDisplayOrder >= to['displayOrder']
            "
            elTestId="v_0nKH9Eqzf3UbWFbUG5N"
            type="button"
            style="vertical-align: middle"
            class="a-button a-button--integrated -without-label"
            (click)="onInfoTextIconClick()"
          >
            <em
              elTestId="WHfEAVCVNMDeTTQjOD-Qn"
              class="a-icon boschicon-bosch-ic-alert-info"
              title="{{ 'ddaap.common.infoTextButton' | translate }}"
            ></em>
          </button>
        </h5>
      </span>
    </div>
  </div>
  <div
    *ngIf="activeTillDisplayOrder >= to['displayOrder']"
    elTestId="x6MonpveMDcyzkjBj_O4a"
    class="body col-md-12"
  >
    <div elTestId="eLkVLExsffXyaRQt1R5-j">
      <div
        elTestId="Y6pHJJ5EIRqwPSF5SpFEL"
        class="description a-text"
      >
        <p elTestId="1n6Uu_petU42NCeB706mm">
          {{ to['description'] }}
        </p>
      </div>
      <div
        elTestId="LKhtLmc4Nu2lHHXW6DX82"
        class="m-form-field driver-input-field"
      >
        <div
          elTestId="Xz1Z10yK0Wxuxl2DRjxaw"
          class="a-text-field"
        >
          <label
            [for]="id" class="length-counter"
          >{{this.control.value?.length || 0}}/{{this.maxLength}}</label
          >
          <input
            elTestId="vky-q3LWH8c9Fh0_2hKuH"
            [formControl]="control"
            [id]="id"
            (keydown)="validateLength($event.key)"
            type="text"
          />
        </div>
        <div
          *ngIf="to['extensionText'] && !(control.invalid && control.dirty)"
          class="a-notification a-notification--text -neutral extension-text"
          role="alert"
        >
          <em
            class="a-icon ui-ic-alert-info"
            title="{{ 'ddaap.common.infoTextButton' | translate }}"
          ></em>
          <div
            id="notification-label-id-text-neutral"
            class="a-notification__content"
          >
            {{ to['extensionText'] }}
          </div>
        </div>
        <ng-container *ngIf="control.invalid && control.dirty">
          <div
            *ngIf="control?.errors?.['pattern']; else customValidation"
            class="a-notification a-notification--text -error"
            role="alert"
          >
            <em
              class="a-icon ui-ic-alert-error"
              title="Validation error"
            ></em>
            <div class="a-notification__content">
              {{ props['validationPatternMessage'] }}
            </div>
          </div>
          <ng-template #customValidation>
            <div
              *ngIf="control?.errors?.['customValidation']; else uniqueDriverName"
              class="a-notification a-notification--text -error"
              role="alert"
            >
              <em
                class="a-icon ui-ic-alert-error"
                title="Validation error"
              ></em>
              <div class="a-notification__content">
                {{ props['validationDependencyMessage'] }}
              </div>
            </div>
          </ng-template>
          <ng-template #uniqueDriverName>
            <div
              *ngIf="control?.errors?.['uniqueDriverName']; else defaultErrorHint"
              id="notification-label-id-text-error"
              class="a-notification a-notification--text -error"
              role="alert"
            >
              <em
                class="a-icon ui-ic-alert-error"
                title="Validation error"
              ></em>
              <div class="a-notification__content">
                {{ 'ddaap.messages.DRIVER_NAME_ALREADY_EXISTS' | translate }}
              </div>
            </div>
          </ng-template>
          <ng-template #defaultErrorHint>
            <div
              elTestId="E01hy1aXKMyixRmbJdZzd"
              class="a-notification a-notification--text -error"
              role="alert"
            >
              <em
                elTestId="JH5iIrDr0McMFKVUmb5sN"
                class="a-icon ui-ic-alert-error"
                title="Validation error"
              ></em>
              <div
                elTestId="mikX57ip6Yxii97J67Hh3"
                id="notification-label-id-text-error"
                class="a-notification__content"
              >
                {{ 'ddaap.messages.INVALID_FIELD' | translate }}
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</div>
