<div
  elTestId="qo-pqVD0-uwYdhLfjr-eY"
  class="fieldset col-md-12 mt-4"
>
  <div
    elTestId="cYwQiDkxuKL6JkdWvE4nm"
    class="header d-flex align-items-center"
  >
    <div
      elTestId="UqCrnHC0Tr13RIcA-iBQk"
      class="a-text"
    >
      <h5
        elTestId="UZktAMBitcKFZfPqTGfJg"
        [ngClass]="{
          'section-disabled': activeTillDisplayOrder < to['displayOrder']
        }"
      >
        {{ to['heading'] }}
        <button
          *ngIf="
            to['infoTextKey'] && activeTillDisplayOrder >= to['displayOrder']
          "
          elTestId="f_ppYd-m5id_5KT5xth7i"
          type="button"
          style="vertical-align: middle"
          class="a-button a-button--integrated -without-label"
          (click)="onInfoTextIconClick()"
        >
          <em
            elTestId="44Rpz0KRKQypqO7u3C5Dq"
            class="a-icon boschicon-bosch-ic-alert-info"
            title="{{ 'ddaap.common.infoTextButton' | translate }}"
          ></em>
        </button>
      </h5>
    </div>
  </div>
  <div
    *ngIf="activeTillDisplayOrder >= to['displayOrder']"
    elTestId="YE3TLwvGVhlIl2OSQIrd2"
    class="body col-md-12"
  >
    <div elTestId="eK5dyn8kjiWJaiRKugNNA">
      <div
        elTestId="XZOP36-p7EO0ilMGqpjOA"
        class="description a-text"
      >
        <p elTestId="eyPym3L6Bnvn-h0KnkG68">
          {{ to['description'] }}
        </p>
      </div>
      <div
        elTestId="ehS5a99-Vbt-ypj5Hre2x"
        class="m-form-field driver-input-field"
      >
        <div
          elTestId="ZTYEKBB0uxpU8ddqMz3aJ"
          class="a-text-field"
        >
          <label
            elTestId="ac3WT2oQOxfdcXtKZ3ePk"
            [for]="id"
            >{{ to.label }}</label
          >
          <input
            elTestId="pD9UqMe5KzxEsNk_g8JN4"
            class="input"
            type="number"
            [formControl]="control"
            [id]="id"
            (keydown)="validateNumericalInput($event.key)"
          />
          <button
            elTestId="1-Ybobxi3pMaYOM8IHEgd"
            type="button"
            class="a-text-field__icon-close"
            (click)="decreaseNumber()"
          >
            <em
              elTestId="hK1vszmVEbpW0Scqowb-L"
              class="a-icon ui-ic-minus"
              title="decrease time raster"
            ></em>
          </button>
          <button
            elTestId="bk0xB_QNjogLPKiWqQTim"
            type="button"
            class="a-text-field__icon-search"
            (click)="increaseNumber()"
          >
            <em
              elTestId="cQl0A10I0kVwbN76zRsBC"
              class="a-icon ui-ic-plus"
              name="timeRasterDeviceRun"
              title="increase time raster"
            ></em>
          </button>
        </div>
        <div
          *ngIf="to['extensionText']"
          class="a-notification a-notification--text -neutral extension-text"
          role="alert"
        >
          <em
            class="a-icon ui-ic-alert-info"
            title="{{ 'ddaap.common.infoTextButton' | translate }}"
          ></em>
          <div
            id="notification-label-id-text-neutral"
            class="a-notification__content"
          >
            {{ to['extensionText'] }}
          </div>
        </div>
        <ng-container *ngIf="control?.invalid && control.dirty">
          <div
            *ngIf="control?.errors?.['min']"
            class="a-notification a-notification--text -error"
            role="alert"
          >
            <em
              class="a-icon ui-ic-alert-error"
              title="Validation error"
            ></em>
            <div class="a-notification__content">
              {{ props['validationMinimumMessage'] }}
              {{min}}.
            </div>
          </div>
          <div
            *ngIf="control.errors?.['max']"
            class="a-notification a-notification--text -error"
            role="alert"
          >
            <em
              class="a-icon ui-ic-alert-error"
              title="Validation error"
            ></em>
            <div class="a-notification__content">
              {{ props['validationMaximumMessage'] }}
              {{ max }}.
            </div>
          </div>
          <div
            *ngIf="control?.errors?.['pattern']"
            class="a-notification a-notification--text -error"
            role="alert"
          >
            <em
              class="a-icon ui-ic-alert-error"
              title="Validation error"
            ></em>
            <div class="a-notification__content">
              {{
                'ddaap.messages.INVALID_FIELD_NUMBER_POSITIVE_INT' | translate
              }}
            </div>
          </div>
          <div
            *ngIf="control?.errors?.['customValidation']"
            class="a-notification a-notification--text -error"
            role="alert"
          >
            <em
              class="a-icon ui-ic-alert-error"
              title="Validation error"
            ></em>
            <div class="a-notification__content">
              {{ props['validationDependencyMessage'] }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
