<div
  elTestId="HsRDVuDdykGLhwK29uwiK"
  class="sdui-toast {{ position }}"
  *ngFor="let item of notifications; trackBy: notificationsTrackByFn"
>
  <div
    elTestId="vw90QJwcL-LR6XLQs4hpY"
    class="sdui-toast-box d-flex align-items-center {{ cssClass(item) }}"
  >
    <div
      elTestId="JLDoK1CIbwziJM-MpI8Z4"
      [ngSwitch]="cssClass(item)"
      style="display: block"
      class="me-3 d-flex"
    >
      <ng-container *ngSwitchCase="'toast-success'">
        <em
          elTestId="1aSLcu4YWa34OF_M7rv2Q"
          class="a-icon ui-ic-checkmark"
          title="checkmark"
        ></em>
      </ng-container>
      <ng-container *ngSwitchCase="'toast-info'">
        <em
          elTestId="mlkMeVWBmDihtz5_1Vnrd"
          class="a-icon ui-ic-info-i"
          title="info"
        ></em>
      </ng-container>
      <ng-container *ngSwitchCase="'toast-error'">
        <em
          elTestId="3SN_25c9aQtAv8XIYP_8t"
          class="a-icon ui-ic-alert-error"
          title="error"
        ></em>
      </ng-container>
      <ng-container *ngSwitchCase="'toast-warning'">
        <em
          elTestId="pJuzqHZunIARyot2M35TE"
          class="a-icon ui-ic-alert-warning"
          title="warning"
        ></em>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <em
          elTestId="DVC9jHPNdkJFT7JaD5DuP"
          class="a-icon ui-ic-checkmark"
          title="checkmark"
        ></em>
      </ng-container>
    </div>
    <div
      elTestId="NDQt4N4w9icUeCg7AGGCw"
      class="sdui-toast-message me-3 bsh-medium"
    >
      <ng-container *ngIf="item.messageIsHTML; else noHTML">
        <div
          elTestId="-HME76Y5YMYIH6YsfI91q"
          class="html-content"
          [innerHTML]="item.message"
        ></div>
      </ng-container>
      <ng-template #noHTML>
        {{ item.message }}
      </ng-template>
    </div>
    <div
      elTestId="DQ1SAcBv2C29DYPfNvsdt"
      (click)="removeNotification(item)"
      class="d-flex close-btn"
    >
      <em
        elTestId="O1_YcgPxcoLhG7-rVjy0V"
        class="a-icon ui-ic-close-small"
        title="close"
      ></em>
    </div>
  </div>
</div>
