<app-container-dialog
  header="{{ 'common.termsTitle' | translate }}"
  subheader="{{ 'common.termsHeadline' | translate }}"
  theme="info"
  [hideCancelButton]="true"
  [confirmButtonDisabled]="!isAccepted"
  [confirmButtonText]="'interface.confirm' | translate"
  (confirmed)="acceptTermsOfUse()"
>
  <div
    elTestId="9U5Gj0pHDf7MjZ15ADlw_"
    class="m-dialog__body"
    id="frontend-kit-dialog-alert-dialog-info-description"
  >
    <p elTestId="chh5nSXNvWm8ZKrKbb7vP">
      <span elTestId="M7uDS23dQ8XRdHla1rtxn">{{
        'common.termsOfUseDescription' | translate
      }}</span>
    </p>
    <div
      elTestId="UPetBdOqwWl13GukJOyeu"
      class="a-checkbox"
    >
      <input
        elTestId="ueoI6zpd7f7g-UTWQ1rGo"
        type="checkbox"
        id="checkbox-multiline-label"
        (change)="toggleTermsOfUse()"
      />
      <label
        elTestId="FPrsg0q3QfdJxkZzTBRy8"
        for="checkbox-multiline-label"
      >
        <p elTestId="gz_Lwd9kvDKBMQB6Dbccu">
          <span elTestId="VUi6pvuErrV9EHFbGnVTI"
            >{{ 'common.termsOfUseAgreement' | translate }}
            <a
              elTestId="iX13MFzFfWS36o7946Ksd"
              target="_blank"
              class="ml-2"
              data-cy="terms-of-use-link"
              href="{{ (content$ | async)?.legalInfo?.termsOfUseUrl }}"
              >{{ 'common.termsOfUse' | translate }}</a
            >
            {{ 'common.haveRead' | translate }}
            <a
              elTestId="g0dHqtmb-BoYiR9mKnJ90"
              target="_blank"
              class="ml-2"
              data-cy="data-protection-notices-link"
              href="{{
                (content$ | async)?.legalInfo?.dataProtectionNoticeUrl
              }}"
              >{{ 'common.dataProtection' | translate }}</a
            >. *</span
          >
        </p>
      </label>
    </div>
  </div>
</app-container-dialog>
