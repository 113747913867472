<app-header></app-header>
<div
  elTestId="BkHkbpnCDOfiUd3ez5Hum"
  class="e-container -full-width"
>
  <main
    elTestId="TjanxlcBrn6kG4sbTJUpl"
    class="main-content"
    [ngClass]="{
      'main-content--padded': !(iotInsightService.actionTypeIsInsight$ | async)
    }"
  >
    <bosch-banner-display-manager
      [displaySubscriptionBanner]="true"
      [displaySystemMaintenanceBanner]="true"
    >
    </bosch-banner-display-manager>
    <ng-content></ng-content>
  </main>
</div>
<public-footer></public-footer>
<app-terms
  *ngIf="
    !(profile$ | async)?.hasAcceptedTermsAndConditions &&
    !!(subscriptions$ | async)?.length
  "
></app-terms>
